<template>
    <div>
        <div class="complete-container">
            <div class="complete-content">
                <p class="title"> Номер вашего заказа: <b>{{}}</b></p>
                <p class="text">Мы скоро с Вами свяжемся <br>
                                звоните по телефону <span style="white-space: nowrap;"><b> +38 (067) 751 11 10</b></span> <br>
                                пишите на почту <b>craft.spirits.ua@gmail.com</b></p>
            </div>

            <router-link to='/shop/0'>
            <Button class="p-button-outlined p-button-secondary" type="button" label="Вернуться к товарам" icon="pi pi-angle-left"/>
            </router-link>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import OrderService from '../service/OrderService';
import { mapState, mapMutations } from 'vuex';

export default {
    props: ['id'],
    data () {
        return {
        }
    },
    productService: null,
    orderService: null,
    created() {
        this.orderService = new OrderService();
        this.orderService.order = JSON.parse(JSON.stringify(this.globalOrder));
	},
    
    mounted() {
        this.setOrder({});
        this.setCartCount(0);
		console.log(this.globalOrder)
	},
    computed: mapState({
        globalOrder: state => state.orders.globalOrder,
		
    }),

    methods: {
        ...mapMutations([
			'setOrder',
			'setCartCount' // `this.increment()` будет вызывать `this.$store.commit('increment')`
		]),
    }
}
</script>

<style scoped>
.complete-container {
    max-width: 70%;
    margin: 0px auto;
}

.complete-content {
    padding: 30px 0px;
    font-size: 20px;
}
</style>
